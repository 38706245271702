.button--kickstarter {
  // font-family: 'Heartstrings', 'Odudo', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  @include button-style($kickstarter, scale-color($kickstarter, $lightness: -15%), white);
  height: 4rem;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(black, .35);
  max-width: 560px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  &:active {
    box-shadow: none;
  }
}
