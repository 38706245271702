@font-face {
  font-family: 'Odudo';
  src: url('../../fonts/OdudoSoft-Regular.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'Odudo';
  src: url('../../fonts/OdudoSoft-Bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'Odudo';
  src: url('../../fonts/OdudoSoft-Light.otf');
  font-weight: lighter;
}

@font-face {
  font-family: 'Odudo';
  src: url('../../fonts/OdudoSoft-SemiBold.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Heartstrings';
  src: url('../../fonts/Heartstrings-Regular.otf');
  font-weight: normal;
}
