.icon-text {
  margin-bottom: 3rem;

  @include breakpoint(large) {
    margin-bottom: 0;
  }

  .icon-text__intro {

    .image {
      margin-bottom: 1.5rem;
    }

    h4 {
      color: inherit;
      text-transform: uppercase;
      line-height: 1.2;
      margin-bottom: .5rem;
    }



    @include breakpoint(medium) {
      display: flex;

      .image {
        flex: 1 0 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 1rem;

        img {
          flex: 1 0 100%;
        }
      }

      h4 {
        flex: 1 1 auto;
        word-spacing: $global-width;
        margin-bottom: 0;
      }
    }
  }

  p {
    // font-weight: 500;
    margin-bottom: 0;
  }
}
