@import "misc/easings";
@import "settings";
@import "fonts";
@import "font-awesome/scss/font-awesome";

@import "foundation-settings";
@import "foundation-framework";

@include foundation-everything($flex: true);

@import "bourbon/app/assets/stylesheets/bourbon";
@import "utility";

@import "base/type";

@import "modules/buttons";
@import "modules/icon-text";
@import "modules/nav";
@import "modules/person";
@import "modules/qanda";
@import "modules/section";

@include breakpoint(small only) {
  html, body {
    font-size: 14px;
  }
}

.language {
  margin: .5rem;

  ul, li {
    display: inline-block;
    margin-bottom: 0;
  }

  span {
    display: inline-block;

    &:after {
      content: ":";
    }
  }

  a {
    display: block;
    font-weight: bold;
  }
}
