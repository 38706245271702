section.section {
  padding: 5rem 1rem;
  position: relative;

  @include breakpoint(small only) {
    text-align: center;
  }

  &--green {
    background-color: map-get($foundation-palette, primary);
    color: white;

    h2 {
      color: map-get($foundation-palette, secondary);
    }
  }

  &--background {
    // padding-top: 0;
    padding-bottom: 2.5rem;
    min-height: 50vh;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    text-align: left;



    .content {
      flex: 1 0 100%;

      color: white;
      position: relative;
      z-index: 1;
      max-width: $global-width;
      margin: 5rem auto 0;

      h1, h2, h3, h4, h5, h6 {
        color: inherit;
      }
    }
  }
}

.section__top {
  padding-bottom: 3rem;
  text-align: center;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;

  p {
    // font-size: 1.25rem;
  }
}

.section__headline {
  &--quote {
    transform: skewX(-7deg);

    &:before, &:after {
      content: "\"";
    }
  }
}

.section.section--narrow {
  > .row {
    max-width: 640px;
  }
}

.section.section--story {
  p {
    margin-bottom: 2.5rem;
  }
}

.section.section--intro {
  .image {
    text-align: center;
    margin-bottom: 5rem;

    img {
      @include breakpoint(small only) {
        max-width: 200px;
      }
    }
  }

  h1 {
    font-weight: bold;
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 0;

    &:after {
      content: "";
      height: 6px;
      width: 5rem;
      background-color: map-get($foundation-palette, secondary);
      display: block;
      margin: .5em auto;

      @include breakpoint(medium) {
        margin-left: 0;
      }
    }
  }

  p {
    font-size: 22.5px;
    color: map-get($foundation-palette, primary);
    font-weight: bold;
    margin-bottom: 2.5rem;
  }

  .button--kickstarter {
    margin-bottom: 5rem;
    margin-left: 0;
  }

  .video-wrapper {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
  }

  .video {
	  position: relative;
  	padding-bottom: 56.25%; /* 16:9 */
  	height: 0;
  }

  .video object,
  .video embed,
  .video iframe {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  }
}

.section__people {
  .button--top {
    margin-bottom: 5rem;
  }
}
