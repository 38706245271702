.fill {
  height: 100%;
  width: 100%;
  position: relative;
}

.fill-parent {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.fill-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.fill-overlay:after {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: '';
  display: block;
  background-color: rgba(black, .25);
}

.fill-blurred {
  filter: blur(1px);
}

.fill-fixed {
  background-attachment: fixed;
}

.mask {
  overflow: hidden;
}

.bring-forward {
  z-index: 1;
  position: relative;
}
