a:not(.button) {
  transition: color .1s linear;
}

h2, h3 {
  font-family: 'Heartstrings', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

h4 {
  font-weight: bold;
}
