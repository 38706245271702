$kickstarter: #2BDE73;
$facebook: #3b5998;
$twitter: #55acee;
$google: #dd4b39;
$youtube: #e52d27;
$instagram: #125688;
$mail: #372f2c;

$grid-column-gutter: (
  small: 20px,
  medium: 30px,
  large: 30px
);
