.person {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2.5rem;

  @include breakpoint(medium) {
  flex-wrap: nowrap;
  }

  .image {
    flex: 1 0 100%;

    @include breakpoint(medium) {
      flex: 1 0 120px;
    }

    img {
      width: 90px;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 1.25rem;

      @include breakpoint(medium) {
        width: 120px;
        margin-bottom: 0;
      }
    }
  }

  .text {
    flex: 1 1 100%;

    @include breakpoint(medium) {
      flex: 1 1 auto;
      margin-left: 1rem;
    }
  }
}
